// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	text-decoration: none;
	color: #838383;
	font-size: rem(15px);
	letter-spacing: 1px;
	text-transform: uppercase;
	font-weight: 600;
	transition: 0.3s;

	@include breakpoint(large,max) {
	padding: rem(20px) rem(40px);
	color: #838383;
	text-transform: none;
	font-size: rem(18px);
	border-bottom: 2px solid $border;
	font-weight: 600;
	letter-spacing: 0px;
	}

	@include breakpoint(large) {
	font-weight: 300;
	}

	@include breakpoint(giant) {
	letter-spacing: 2px;
	font-size: rem(17px);
	}
}
%buttonHover {
	// Link Hover
	color: $primary;
	background-color: transparent;

	@include breakpoint(large,max) {
	color: $light;
	background-color: $primary;
	}
}
%buttonActive {
	// Link Active
	color: $primary;
	background-color: transparent;

	@include breakpoint(large,max) {
	color: $light;
	background-color: $primary;
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, arrow-right);
	transform: rotateY(180deg);
	font-weight: 400;
	color:rgba($light,.6);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, arrow-right);
	font-weight: 400;

	@include breakpoint($break) {
		transform:rotate(90deg);
		font-size: 80%;
		color:rgba($dark,.6);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 70px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $light; // background-color
$barColor: $primary; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		@include ul {
			&.navi {
				li {

					&.impressum, &.datenschutzerklaerung, &.facebook, &.lebensweisheiten {
						display: none;
					}

					&.anfahrt, &.heilsitzungFuerTiere, &.eventsReisen {
						@include breakpoint(large,max) {
						margin-bottom: rem(200px);
						}
					}

					@include navButtons {
						@include navButton;
					}
					@include hasSub {

						li {

							&.termine {
								display: none;
							}
							
							a {
								text-transform: none;
								letter-spacing: 0;
								font-weight: 300;

								@include breakpoint(large,max) {
								padding: rem(20px) rem(40px);
								}
							}
						}
						@include sub {
							background: $navBg;

							&:before {
								display: none;
							}
						}
					}
					@include navBack {
						background: $secondary;
						color:$light;
						font-size: rem(16px);
						text-transform: none;
						letter-spacing: 0;
						padding: rem(20px) rem(40px);

						&:before {
							color:$light;
						}
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		width: auto;
		position: relative;
		top: inherit;

		nav {
			background: #f0faff;
			ul {
				&.navi {
					li {

						&.energiearbeit.hasSub {
							.sub  {
								column-count:2;

								li {

									break-inside: avoid;
								}
							}
						}

						&.hasSub {
							.sub {
								background: none;
								// column-count:2;

								li {
									margin-bottom: 2px;
									// break-inside: avoid;
									
									a {
										display: block;
										background: $primary;
										color:$light;
										padding: rem(15px) rem(20px);

										&:hover, &.active, &:focus {
										background: $secondary;
										color:$light;
										}

									}
								}
							}
						}
						&.navBack {
							display: none;
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	box-shadow: 0 0 10px 3px rgba($dark,.2);

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	background: $medium;
	padding: rem(25px);
	

	@include breakpoint(large) {
	margin-top: rem(40px);
	}

	@include breakpoint(huge) {
	margin: 0;
	padding: 0;
	background: none;
	float: right;	
	}
	

	li {
		display: block;

		@include breakpoint(medium) {
		display: inline-block;
		margin: 0 rem(15px);	
		}

		@include breakpoint(large) {
		margin: 0 rem(20px);	
		}

		@include breakpoint(huge) {
			margin: 0 0 0 rem(40px);
		}

		a {
			text-decoration: none;
			font-weight: 600;
			transition: 0.3s;
			color:$text;

			@include breakpoint(medium,max) {
			border-bottom: 2px solid rgba($primary,.1);
			padding: rem(10px) 0;
			display: block;
			}

			&:hover, &.active, &:focus {
				color:$secondary;
			}
		}

		&.facebook {
			a {
				background: url(../images/layout/facebook.png) no-repeat center center;
				width: rem(34px);
				height: rem(34px);
				display: inline-block;
				border-bottom: 0;

				@include breakpoint(medium,max) {
				margin-top: rem(10px);
				}

				@include breakpoint(medium) {
				position: relative;
				top:-4px;	
				}

				span {
					display: inline-block;
					text-indent: -99999px;
				}

				&:hover {
					opacity: .7;
				}
			}
		}
	}
}