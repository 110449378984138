#pageWrap {
	margin-top: rem(70px);

	@include breakpoint(large) {
		margin-top: rem(240px);
	}

	@include breakpoint(giant) {
		margin-top: rem(306px);
	}
}

// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;

	.claimContainer {
		text-align: center;
		padding: rem(50px) 0;
		background: url(../images/layout/tiny-bgHeader.jpg) no-repeat center center/cover;

		@include breakpoint(tiny) {
			background: url(../images/layout/medium-bgHeader.jpg) no-repeat center center/cover;
		}

		@include breakpoint(small) {
			padding: rem(100px) 0;
		}

		@include breakpoint(medium) {
			background: url(../images/layout/giant-bgHeader.jpg) no-repeat center center/cover;
		}

		@include breakpoint(large) {
			padding: rem(200px) 0;
		}

		@include breakpoint(giant) {
			padding: rem(250px) 0;
			background: url(../images/layout/full-bgHeader.jpg) no-repeat center center/cover;
		}

		@include breakpoint(full) {
			padding: rem(340px) 0;
			background: url(../images/layout/bgHeader.jpg) no-repeat center center/cover;
		}

		&.lazyLoaded {
			background-size: cover;
			background-attachment: fixed;
		}

		body:not(.index) & {
			display: none;
		}

		.claim {
			color: $light;

			p {
				font-size: rem(45px);
				line-height: rem(50px);
				font-weight: 600;

				@include breakpoint(tiny) {
					line-height: rem(65px);
				}

				@include breakpoint(small) {
					font-size: rem(60px);
					line-height: rem(70px);
				}

				@include breakpoint(large) {
					font-size: rem(100px);
					line-height: rem(110px);
				}

				@include breakpoint(giant) {
					font-size: rem(120px);
					line-height: rem(130px);
				}

				@include breakpoint(full) {
					font-size: rem(150px);
					line-height: rem(160px);
				}

				span {
					display: block;
					font-weight: 500;
					font-size: rem(23px);
					line-height: rem(32px);

					@include breakpoint(small) {
						font-size: rem(32px);
						line-height: rem(36px);
					}

					@include breakpoint(medium) {
						font-size: rem(35px);
						line-height: rem(40px);
					}

					@include breakpoint(large) {
						font-size: rem(45px);
						line-height: rem(50px);
					}

					@include breakpoint(giant) {
						font-size: rem(55px);
						line-height: rem(60px);
					}

					@include breakpoint(full) {
						font-size: rem(65px);
						line-height: rem(70px);
					}
				}

				.claim_verkleinert {
					font-size: rem(18px);

					@include breakpoint(small) {
						font-size: rem(22px);
					}

					@include breakpoint(medium) {
						font-size: rem(25px);
					}

					@include breakpoint(large) {
						font-size: rem(30px);
					}

					@include breakpoint(giant) {
						font-size: rem(40px);
					}

					@include breakpoint(full) {
						font-size: rem(50px);
					}
				}
			}

			small {
				display: block;
				font-weight: 300;
				font-size: rem(16px);
				line-height: rem(28px);
				padding-top: rem(10px);

				@include breakpoint(small) {
					font-size: rem(18px);
					line-height: rem(30px);
				}

				@include breakpoint(large) {
					font-size: rem(20px);
					line-height: rem(32px);
				}

				@include breakpoint(giant) {
					font-size: rem(25px);
					line-height: rem(35px);
					padding-top: rem(20px);
				}
			}

		}
	}

	.headerContainer {

		@include breakpoint(large) {
			position: fixed;
			top: 0;
			width: 100%;
			background: $light;
			z-index: 100;
		}

		.branding {
			display: block;
			max-width: 80px;
			width: 100%;
			height: auto;
			margin: 0 auto;
			position: relative;
			z-index: 1;
			margin: rem(25px) auto;
			transition: 0.6s;

			@include breakpoint(large, max) {
				display: none;
			}

			@include breakpoint(giant) {
				max-width: 117px;
			}

			body.scrollingDown & {
				@include breakpoint(large) {
					max-width: 40px;
					margin: rem(15px) auto;
				}

				@include breakpoint(full) {
					max-width: 60px;
				}
			}
		}

		.naviContainer {
			@include breakpoint(large) {
				background: #f0faff;
				padding: rem(30px) rem(15px);
				width: 100%;
				transition: 0.6s;
			}

			@include breakpoint(giant) {
				padding: rem(40px) rem(15px);
			}

			body.scrollingDown & {
				@include breakpoint(large) {
					padding: rem(20px) rem(15px);
				}

			}
		}

	}



	picture {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		height: auto;
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;


	.preis {
		color: $primary;
		font-weight: 600;
		display: block;
		margin-bottom: rem(25px);
	}

	.zeit {
		font-weight: 600;
		color: rgba($dark, .7);
		margin-bottom: rem(15px);
		display: block;
	}

	.newsContainer {
		background: $primary;
		color: $light;
		padding: rem(50px) rem(25px);

		@include breakpoint(medium) {
			padding: rem(75px) rem(25px);
		}

		@include breakpoint(large) {
			padding: rem(75px) rem(0px);
		}

		@include breakpoint(giant) {
			padding: rem(100px) 0;
		}

		p {
			color: $light;

			@include breakpoint(large, max) {
				font-size: rem(16px);
				line-height: rem(27px);
			}

			&.h5 {
				color: $light;

				@include breakpoint(large, max) {
					font-size: rem(20px);
					line-height: rem(30px);
				}
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}

	}

	img {
		// &.imgFullEnergie {
		// 	margin-bottom: 0;

		// 	@include breakpoint(large) {
		// 		height: rem(955px);
		// 		object-fit: cover;
		// 	}

		// 	@include breakpoint(giant) {
		// 		height: rem(992px);	
		// 	}

		// 	@include breakpoint(huge) {
		// 		height: rem(902px);	
		// 	}
		// }

		// &.imgFullSeminare {
		// 	margin-bottom: 0;

		// 	@include breakpoint(large) {
		// 		height: rem(790px);
		// 		object-fit: cover;
		// 	}

		// 	@include breakpoint(giant) {
		// 		height: rem(1000px);	
		// 	}

		// 	@include breakpoint(huge) {
		// 		height: rem(895px);	
		// 	}
		// }

		&.imgAbstand {
			margin-bottom: 0;
		}

		&.imgAbstandBottom {
			@include breakpoint(large, max) {
				margin-bottom: rem(25px);
			}
		}
	}

	.breakLeft {

		&.order {
			.rowLeft {

				@include breakpoint(large, max) {
					order: 2;
				}
			}
		}
	}


	.bgBlue {
		background: $medium;
	}

	.teaserContainer {
		background: $medium;
		margin: rem(50px) 0 0;
		text-align: center;

		@include breakpoint(medium) {
			margin: rem(100px) 0 0;
		}

		@include breakpoint(large) {
			margin: 0;
		}

		.col {
			// padding: rem(30px) $baseGap;

			a {
				display: block;
				text-decoration: none;

				span {
					display: block;
					font-weight: 700;
					font-size: rem(22px);
					color: $dark;

					&.first,
					&.second,
					&.third {
						padding-bottom: rem(50px);

						@include breakpoint(large) {
							padding-bottom: 0;
						}
					}

					&.third {
						@include breakpoint(small) {
							padding-bottom: 0;
						}
					}
				}

				&:before {
					color: $primary;
					display: block;
					font-weight: 400;
					font-size: rem(70px);
					padding-bottom: rem(20px);
					transition: 0.6s;

					@include breakpoint(giant) {
						font-size: rem(90px);
					}
				}

				&:hover {
					&:before {
						color: $secondary;

						@include breakpoint(giant) {
							font-size: rem(80px);
						}
					}
				}

				&.lebensweisheiten {
					&:before {
						@extend .icon;
						@extend .icon-herz;
					}
				}

				&.meditation {
					&:before {
						@extend .icon;
						@extend .icon-meditation;
					}
				}

				&.events {
					&:before {
						@extend .icon;
						@extend .icon-events;
					}
				}

				&.seminare {
					&:before {
						@extend .icon;
						@extend .icon-kunden;
					}
				}
			}
		}
	}

	.separator {
		text-align: center;
		padding: rem(100px) 0;
		@include responsiveImage('index/bgSeparator.jpg', (tiny, medium, giant, full), true);

		@include breakpoint(small) {
			padding: rem(200px) 0;
		}

		@include breakpoint(giant) {
			padding: rem(250px) 0;
		}

		@include breakpoint(full) {
			padding: rem(340px) 0;
		}

		&.lazyLoaded {

			@include breakpoint(large) {
				background-size: cover;
				background-attachment: fixed;
			}
		}

		p {
			color: $light;
			font-size: rem(25px);
			line-height: rem(40px);
			font-weight: 600;

			@include breakpoint(medium) {
				font-size: rem(35px);
				line-height: rem(50px);
			}

			@include breakpoint(giant) {
				font-size: rem(40px);
				line-height: rem(60px);
			}

			@include breakpoint(large, max) {
				padding: 0 rem(25px);
			}
		}
	}

	.separatorUeberMich {
		text-align: center;
		padding: rem(200px) 0;
		@include responsiveImage('ueber-mich/bgSeparator.jpg', (tiny, medium, giant, full), true);

		@include breakpoint(giant) {
			padding: rem(250px) 0;
		}

		@include breakpoint(full) {
			padding: rem(340px) 0;
		}

		&.lazyLoaded {

			@include breakpoint(large) {
				background-size: cover;
				background-attachment: fixed;
			}
		}
	}



	.bgWhite {
		background: $light;
		padding: rem(25px);
		margin-top: rem(30px);

		@include breakpoint(medium) {
			padding: rem(50px);
		}

		@include breakpoint(giant) {
			padding: rem(75px);
			margin-top: rem(50px);
		}
	}

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;

		a {
			transition: 0.3s;
		}

		&.last {
			margin-bottom: 0;
		}
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom: rem($baseGap*2);
	}
}

#privacyPolicy {
	a {
		display: inline-block;
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	background: $light;

	.nlContainer {
		text-align: center;
		padding: rem(100px) 0 rem(300px);
		@include responsiveImage('layout/bgFooter.jpg', (tiny, medium, giant, full), true);

		@include breakpoint(medium) {
			padding: rem(150px) 0 rem(400px);
		}

		@include breakpoint(giant) {
			padding: rem(200px) 0 rem(500px);
		}

		@include breakpoint(full) {
			padding: rem(200px) 0 rem(600px);
		}

		&.lazyLoaded {
			background-size: cover;

			@include breakpoint(giant) {
				background-position: center top;
			}
		}

		.highlight {
			background: $secondary;
			padding: rem(25px);
			color: $light;
			margin-bottom: rem(20px);
		}

		body.cmsBackend & {
			display: none;
		}

		p {
			&.headline {
				font-size: rem(30px);
				line-height: rem(40px);
				font-weight: 600;
				color: $dark;

				@include breakpoint(tiny) {
					font-size: rem(35px);
					line-height: rem(45px);
				}

				@include breakpoint(medium) {
					font-size: rem(40px);
					line-height: rem(50px);
				}

				@include breakpoint(giant) {
					font-size: rem(50px);
					line-height: rem(60px);
				}

				span {
					display: block;
					font-weight: 600;
					color: rgba($dark, .6);
					font-size: rem(18px);
					line-height: rem(28px);

					@include breakpoint(tiny) {
						font-size: rem(21px);
						line-height: rem(31px);
					}

					@include breakpoint(medium) {
						font-size: rem(25px);
						line-height: rem(35px);
					}

					@include breakpoint(giant) {
						font-size: rem(30px);
						line-height: rem(40px);
					}
				}
			}
		}
	}

	.end {
		text-align: center;
		padding: rem(50px) 0;

		@include breakpoint(medium) {
			padding: rem(75px) 0;
		}

		@include breakpoint(giant) {
			padding: rem(100px) 0;
		}

		p {
			font-weight: 600;
			margin-bottom: 0;

			span {
				display: block;
				font-weight: 300;
			}

			@include breakpoint(large) {
				text-align: left;
			}
		}
	}

	img {
		@include breakpoint(large, max) {
			margin: rem(40px) 0;
		}
	}
}


//////////////////////////////
//////////////////////////////
//////////////////////////////

.padding {
	padding: rem(50px) 0;

	@include breakpoint(medium) {
		padding: rem(100px) 0;
	}

	@include breakpoint(giant) {
		padding: rem(150px) 0;
	}
}

.paddingTop {
	padding: rem(50px) 0 0;

	@include breakpoint(medium) {
		padding: rem(100px) 0 0;
	}

	@include breakpoint(giant) {
		padding: rem(150px) 0 0;
	}
}

.paddingBottom {
	padding: 0 0 rem(50px) 0;

	@include breakpoint(medium) {
		padding: 0 0 rem(100px) 0;
	}

	@include breakpoint(giant) {
		padding: 0 0 rem(150px) 0;
	}
}

.paddingLeft {
	@include breakpoint(large) {
		padding-left: rem(50px);
	}

	@include breakpoint(giant) {
		padding-left: rem(75px);
	}

	@include breakpoint(full) {
		padding-left: rem(100px);
	}
}

.paddingRight {
	@include breakpoint(large) {
		padding-right: rem(50px);
	}

	@include breakpoint(giant) {
		padding-right: rem(75px);
	}

	@include breakpoint(full) {
		padding-right: rem(100px);
	}
}

.flexMiddle {
	display: flex;
	align-items: center;
	justify-content: center;
}

.center {
	text-align: center;
}

.marginNoBottom {
	margin-bottom: 0 !important;
}



.paddingContainer {
	padding: rem(50px) 0;

	@include breakpoint(medium) {
		padding: rem(100px) 0;
	}

	@include breakpoint(giant) {
		padding: rem(150px) 0;
	}
}

.rowRight {
	@include breakpoint(large) {
		padding-right: 0;
		padding-left: 0;
	}
}

.rowLeft {
	@include breakpoint(large) {
		padding-left: 0;
		padding-right: 0;
	}
}

.marginTop {
	margin: rem(20px) 0 0;

	@include breakpoint(large) {
		margin: rem(40px) 0 0;
	}
}


.btnpad {
	padding-top: 1.11111rem;
}

.subNavigation {

	li {
		display: flex;
		margin-bottom: rem($baseGap)*2;

		a {
			display: block;
			text-decoration: none;
			color: $dark;
			background: $light;
			box-shadow: 0 0 rem(20px) rgba(black, .1);
			padding: rem(30px);

			span {
				display: block;

				&:not(.readMore) {
					margin-bottom: rem(10px);
				}
			}
		}
	}
}

// .newsletter-notification {
// 	margin-top: 2rem;

// 	position: absolute !important;
// 	bottom: rem(100px) !important;
// 	left: 10px !important;

// 	.success,
// 	.error {
// 		padding: 1rem 1rem;
// 		max-width: 400px !important;
// 		color: $light !important;
// 	}

// 	.success {
// 		background-color: green !important;

// 		p {
// 			margin-bottom: 0 !important
// 		}
// 	}

// 	.error {
// 		background-color: red;


// 		ul {
// 			margin-bottom: 0 !important;

// 			li {
// 				padding-left: 0 !important;
// 				margin-bottom: 0 !important;

// 				&::before {
// 					display: none !important;

// 				}
// 			}
// 		}
// 	}
// }