/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 30px;

$h1Size: 32px;

$h2Size: 25px;

$h3Size: 25px;

$h4Size: 20px;

$h5Size: 20px;

$h6Size: 20px;

$mainFont: Montserrat, sans-serif;

$displayFont: "Montserrat", sans-serif;

$iconFont: icomoon;

// Project colors

$primary: #005a8f;
$secondary: #98bf0e;
$light: #fff;
$medium: #e0f2fb;
$dark: #11283e ;
$border: #e7e9eb;
$alert: #005a8f;
$text: #415365;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 2px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	angle-right: "❯", 
	plus: "+", 
	angle-up: "↑", 
	exclamation: "!", 
	arrow-down: "\e90c", 
	arrow-up: "\e910", 
	arrow-left: "\e90d", 
	arrow-right: "\e90e", 
	check: "\e905", 
	calender: "\e913", 
	phone: "\e90b", 
	pdf: "\eadf", 
	mail: "\e908", 
	link: "\e907", 
	meditation: "\e900", 
	events: "\e901", 
	herz: "\e903", 
	kunden: "\e904", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (4, 8),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (4, 6, 8),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (4, 8),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (2, 3, 4, 6, 8, 9, 10, 12),
		prefix: (1),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (2, 4, 6, 8, 10),
		prefix: (2),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (2, 5),
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (6, 8, 10),
		prefix: (1, 2, 3),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

