// Use this for default Icomoon

@include fontFaceV2(
 $fontName: "icomoon",
 $fileName: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);

@include fontFaceV2(
    $fontName: "Montserrat", 
    $fileName: "montserrat-v14-latin", 
    $weights: ("300", "500", "600", "700"), 
    $types: all
);